import { useQuery } from "react-query";
import { useCommunityInboxStore } from "@/react/hooks/chatsV2/store";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers/internalApi";

export const useCommunityChat = (id: number) => {
  const { setCurrentChat } = useCommunityInboxStore();
  const fetchChat = () =>
    reactQueryGet(internalApi.communityInbox.show({ id }));

  const { isLoading } = useQuery(
    ["community-inbox", "chat-room", id],
    fetchChat,
    {
      onSuccess: chat => {
        setCurrentChat(chat);
      },
      enabled: !!id,
    },
  );

  return {
    isChatLoading: isLoading,
  };
};
