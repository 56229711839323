import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { settingsNavPath } from "@circle-react/helpers/urlHelpers";
import type { ViewSwitcherLayoutTypes } from "@circle-react-shared/ViewSwitcher";
import { ViewSwitcher } from "@circle-react-shared/ViewSwitcher";
import { Button } from "@circle-react-uikit/Button";
import { ManageMemberDirectoryActionsV3 } from "./ManageMemberDirectoryActionsV3";
import { PublicSettingButton } from "./PublicSettingButton";

export interface RightOptionsProps {
  activeView: ViewSwitcherLayoutTypes;
  setActiveView: (value: ViewSwitcherLayoutTypes) => void;
}

export const RightOptions = ({
  activeView,
  setActiveView,
}: RightOptionsProps) => {
  const history = useHistory();
  const { currentCommunityMember } = usePunditUserContext();

  const viewSwitcherEl = (
    <ViewSwitcher value={activeView} onChange={setActiveView} />
  );

  if (isCommunityAdmin(currentCommunityMember)) {
    return (
      <div className="flex items-center gap-4">
        {viewSwitcherEl}
        <PublicSettingButton />
        <Button
          large
          type="button"
          className="hidden md:block"
          onClick={() => history.push(settingsNavPath.members.manage)}
        >
          {t("manage")}
        </Button>
        <ManageMemberDirectoryActionsV3 />
      </div>
    );
  }

  return <>{viewSwitcherEl}</>;
};
