import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { BadgeV2 } from "@/react/components/shared/uikit/BadgeV2";
import { isLegacyAccessSystemAvailable } from "@/react/helpers/accessGroups/accessGroupHelpers";
import { TippyV2 } from "@circle-react/components/shared/TippyV2";
import {
  useCurrentSpaceContext,
  usePunditUserContext,
  useSpaceGroupsContext,
} from "@circle-react/contexts";
import { isChatSpace } from "@circle-react/helpers/spaceHelpers";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { settingsNavPath } from "@circle-react/helpers/urlHelpers";
import { OrDivider } from "@circle-react-shared/OrDivider";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";
import { Modal, useModalData } from "@circle-react-uikit/ModalV2";
import { Typography } from "@circle-react-uikit/Typography";
import { MembersToAddList } from "./MembersToAddList";

const ADD_FROM_OPTIONS = [
  {
    label: t("spaces.form.add_everyone_from_options.space_group"),
    value: "space_group",
  },
  {
    label: t("spaces.form.add_everyone_from_options.community"),
    value: "community",
  },
];
const communityMemberSelectName = "community_member_ids";
const shouldWatchAllMembersName = "should_add_all_members";

export interface AddMemberContentProps {
  onClose: () => void;
  isLoading?: boolean;
  formId: string;
}

export const AddMemberContent = ({
  onClose,
  isLoading,
  formId,
}: AddMemberContentProps) => {
  const { watch, setValue } = useFormContext();
  const { contentPortalElement } = useModalData();
  const { currentCommunity, currentCommunitySettings } = usePunditUserContext();
  const { pathname } = useLocation();

  const chatSpaceMembersLimit = currentCommunity?.chat_space_members_limit || 0;
  const communityMemberCount = currentCommunity?.member_count || 0;

  const { data: space } = useCurrentSpaceContext();
  const {
    helpers: { findBy },
  } = useSpaceGroupsContext();
  const spaceGroup = findBy({ id: space?.space_group_id });
  const { space_group_members_count: spaceGroupMemberCount = 0 } =
    spaceGroup || {};

  const memberIds: string[] = watch(communityMemberSelectName, []) || [];
  const shouldAddEveryone = watch(shouldWatchAllMembersName, false);
  const memberType = watch("member_type");
  const hasMembers = memberIds.length > 0;

  const removeMemberId = (memberId: string) => {
    const newMemberIds = memberIds.filter(id => id !== memberId);
    setValue(communityMemberSelectName, newMemberIds);
  };

  const membersAdded = hasMembers || shouldAddEveryone;

  const isSpaceGroupToggleDisabled =
    spaceGroupMemberCount > chatSpaceMembersLimit;
  const isCommunityToggleDisabled =
    communityMemberCount > chatSpaceMembersLimit;

  const isMemberTypeSpaceGroup = memberType === "space_group";

  const isToggleDisabled =
    isChatSpace(space) &&
    (isMemberTypeSpaceGroup
      ? isSpaceGroupToggleDisabled
      : isCommunityToggleDisabled);

  useEffect(() => {
    if (isToggleDisabled) {
      setValue(shouldWatchAllMembersName, false);
    }
  }, [isToggleDisabled]);

  return (
    <>
      <Modal.Header>
        <Modal.Title size="sm">
          {t("spaces.form.edit.members.add_member")}
        </Modal.Title>
        <Modal.HeaderActions>
          <Modal.CloseButton onClick={onClose} />
        </Modal.HeaderActions>
      </Modal.Header>
      <Modal.Body className="!px-5 !py-4 md:!px-8 md:!py-2">
        <div className="mb-4">
          <Typography.LabelSm>
            {t("add_members_to_space_description_without_link")}{" "}
            <a
              href={`${settingsNavPath.members.invite}?invite_type=csv&from=${pathname}`}
            >
              {t("add_members_to_space_description_link")}
            </a>
          </Typography.LabelSm>
        </div>
        <Form.CommunityMemberSelect
          name={communityMemberSelectName}
          variant="tags"
          direction="down"
          addable
          hideSelectedItems
          hideIcon
          disabled={shouldAddEveryone}
          placeholder={t("spaces.form.edit.members.add_member")}
          appendTo={contentPortalElement}
          popperOptions={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [-10, 0],
                },
              },
            ],
          }}
        />
        <MembersToAddList memberIds={memberIds} onRemove={removeMemberId} />
        {isLegacyAccessSystemAvailable(currentCommunitySettings) && (
          <div
            className={classNames({
              hidden: hasMembers,
              visible: !hasMembers,
            })}
          >
            <OrDivider />
            <div className="flex gap-1 pt-4">
              <div>
                <Typography.LabelSm weight="semibold">
                  {t("settings.access_groups.admin.legacy_access.title")}
                </Typography.LabelSm>
              </div>
              <div>
                <TippyV2
                  role="tooltip"
                  content={t(
                    "settings.access_groups.admin.legacy_access.legacy_tooltip",
                  )}
                  interactive={false}
                >
                  <BadgeV2
                    square
                    label={t(
                      "settings.access_groups.admin.legacy_access.legacy_badge",
                    )}
                  />
                </TippyV2>
              </div>
            </div>
            <div className="flex flex-col items-start md:flex-row md:items-center md:gap-6">
              <TippyV2
                content={
                  isMemberTypeSpaceGroup
                    ? t("members_in_space_group_limit_reached")
                    : t("members_in_community_limit_reached")
                }
                disabled={!isToggleDisabled}
                className="flex w-full"
              >
                <Form.Item
                  hiddenField
                  hideDescription
                  label={t("spaces.form.add_everyone_from")}
                  id={shouldWatchAllMembersName}
                  name={shouldWatchAllMembersName}
                  className="my-5 flex shrink-0 flex-row-reverse items-center justify-end py-0 font-normal"
                  labelClassName="mx-2 mb-0 font-normal text-sm"
                  shouldToggleValueOnLabelClick
                  isDisabled={isToggleDisabled}
                >
                  <Form.ToggleSwitch
                    name={shouldWatchAllMembersName}
                    variant="small"
                    disabled={isToggleDisabled}
                  />
                </Form.Item>
              </TippyV2>
              <Form.Item
                name="member_type"
                className="w-full text-sm font-normal"
                hideDescription
                hideLabel
                hideBorder
                hiddenField
              >
                <Form.SelectNative
                  name="member_type"
                  options={ADD_FROM_OPTIONS}
                />
              </Form.Item>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="flex items-baseline justify-between">
        <div className="-mt-4">
          <Form.Checkbox
            id="notify_members"
            label={t("spaces.form.edit.members.notify_members")}
            name="notify_members"
            labelClassName="font-normal text-sm"
          />
        </div>
        <Button
          large
          variant="primary"
          type="submit"
          form={formId}
          disabled={isLoading || !membersAdded}
        >
          {isLoading
            ? t("spaces.form.edit.members.adding")
            : t("spaces.form.edit.members.add")}
        </Button>
      </Modal.Footer>
    </>
  );
};
