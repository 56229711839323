import { t } from "@/i18n-js/instance";
import { useDeleteSpaceModal } from "@/react/components/Modals/DeleteSpaceModal";
import { useLeaveSpaceConfirmationModal } from "@/react/components/Modals/LeaveSpaceConfirmationModal";
import { canInviteMembers, isCourseSpace } from "@/react/helpers/spaceHelpers";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { useMobileWebFlags } from "@circle-react/hooks/useMobileWebFlags";
import type { Space } from "@circle-react/types";
import { Icon } from "@circle-react-shared/Icon";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { Typography } from "@circle-react-uikit/Typography";
import { useNotificationBell } from "../../SpaceHeaderActions/NotificationBellDropdown/useNotificationBell";
import { DeleteSpaceOption } from "./DeleteSpaceOption";
import { InviteMemberSpaceOptions } from "./InviteMemberSpaceOptions";
import { LeaveSpaceOption } from "./LeaveSpaceOption";
import { useSpaceOptionsIcons } from "./useSpaceOptionsIcons";

export interface LeaveAndDeleteSpaceOptionsProps {
  space: Space;
  isAdmin: boolean;
  onInviteMember?: () => void;
}

export const LeaveAndDeleteSpaceOptions = ({
  space,
  isAdmin,
  onInviteMember,
}: LeaveAndDeleteSpaceOptionsProps) => {
  const { isMobileWebNewSpaceCustomizationUIEnabled } = useMobileWebFlags();
  const deleteSpaceModal = useDeleteSpaceModal();
  const redirectToRoot = () => (window.location.href = "/");
  const leaveSpaceConfirmationModal = useLeaveSpaceConfirmationModal();
  const { isV3Enabled } = useIsV3();
  const { leaveSpaceIcon, deleteSpaceIcon } = useSpaceOptionsIcons();
  const isSpaceCourseSpace = isCourseSpace(space);

  const shouldShowInviteMember =
    isV3Enabled && canInviteMembers(space) && !!onInviteMember;

  const shouldShowLeaveSpace = !!space.policies?.can_leave_space;

  const { shouldShowNotificationsSection } = useNotificationBell({
    space,
  });

  const shouldShowV3Separator =
    isV3Enabled &&
    (isAdmin ||
      shouldShowInviteMember ||
      (shouldShowNotificationsSection && !isSpaceCourseSpace));

  if (isMobileWebNewSpaceCustomizationUIEnabled) {
    return (
      <>
        {shouldShowLeaveSpace && (
          <button
            type="submit"
            className="flex w-full items-center gap-4 px-5 py-2"
            onClick={() => leaveSpaceConfirmationModal.show()}
          >
            <Icon type={leaveSpaceIcon} className="text-v2-danger" size={20} />
            <Typography.LabelSm color="text-v2-danger">
              {t("space_header.leave_space")}
            </Typography.LabelSm>
          </button>
        )}
        {isAdmin && (
          <button
            type="submit"
            className="flex w-full items-center gap-4 px-5 py-2"
            onClick={() =>
              deleteSpaceModal.show({
                space,
                onSuccess: () => {
                  redirectToRoot();
                },
              })
            }
          >
            <Icon type={deleteSpaceIcon} className="text-v2-danger" size={20} />
            <Typography.LabelSm color="text-v2-danger">
              {t("space_header.delete_space")}
            </Typography.LabelSm>
          </button>
        )}
      </>
    );
  }

  return (
    <>
      {!isV3Enabled && isAdmin && <Dropdown.Separator />}
      {shouldShowV3Separator && <Dropdown.Separator />}
      {shouldShowInviteMember && (
        <InviteMemberSpaceOptions
          space={space}
          onInviteMember={onInviteMember}
        />
      )}
      {shouldShowLeaveSpace && <LeaveSpaceOption />}
      {isAdmin && <DeleteSpaceOption />}
    </>
  );
};
