import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import { communityInboxApi } from "@circle-react/api/communityInboxApi";
import { useCommunityInboxStore } from "@circle-react/hooks/chatsV2/store";
import { useToast } from "@circle-react-uikit/ToastV2";

export const usePauseAiAction = () => {
  const { updateChat, setCurrentChat } = useCommunityInboxStore();
  const toastr = useToast();

  const { mutateAsync: pauseAi, isLoading: isPauseAiLoading } = useMutation(
    (id: number) => communityInboxApi.pauseAi({ id }),
    {
      onSuccess: chat => {
        updateChat(chat, "admin");
        setCurrentChat(chat);
        toastr.success(t("messaging.actions.pause_ai_success"));
      },
      onError: err => {
        console.error("Error while creating chat", err);
      },
    },
  );

  const { mutateAsync: resumeAi, isLoading: isResumeAiLoading } = useMutation(
    (id: number) => communityInboxApi.resumeAi({ id }),
    {
      onSuccess: chat => {
        updateChat(chat, "admin");
        setCurrentChat(chat);
        toastr.success(t("messaging.actions.resume_ai_success"));
      },
      onError: err => {
        console.error("Error while creating chat", err);
      },
    },
  );

  return {
    pauseAi,
    isPauseAiLoading,
    resumeAi,
    isResumeAiLoading,
  };
};
