import { differenceInDays, format } from "date-fns";
import { Link } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { settingsNavPath } from "@circle-react/helpers/urlHelpers/settingsNavPath";
import { Button } from "@circle-react-shared/uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";

interface FreePeriodBannerProps {
  freePeriod: any;
}

export const FreePeriodBanner = ({ freePeriod }: FreePeriodBannerProps) => {
  const endsAt = freePeriod?.ends_at;
  const dateWithoutTimeStamp = (date: Date) => new Date(date.toDateString());

  const today = dateWithoutTimeStamp(new Date());
  const freePeriodEndsAt = dateWithoutTimeStamp(new Date(endsAt));
  const daysLeft = Math.max(differenceInDays(freePeriodEndsAt, today), 0);
  const formattedEndDate = format(freePeriodEndsAt, "MMM d, yyyy");

  return (
    <div className="mt-4">
      <div className="dark:bg-tertiary rounded-lg bg-blue-100 p-4">
        <Typography.LabelMd color="text-secondary" weight="medium">
          {t("settings.billing.marketing_hub.free_period_banner.title")}
        </Typography.LabelMd>
        <div className="my-2">
          <Typography.BodySm color="text-secondary">
            {t(
              "settings.billing.marketing_hub.free_period_banner.remaining_days",
              { days_left: daysLeft, end_date: formattedEndDate },
            )}
          </Typography.BodySm>
        </div>
        <div className="mb-4">
          <Typography.BodySm>
            {t("settings.billing.marketing_hub.free_period_banner.banner_text")}
          </Typography.BodySm>
        </div>

        <Link to={settingsNavPath.email.broadcast}>
          <Button onClick={function noRefCheck() {}} variant="secondary">
            <Typography.LabelSm>
              {t(
                "settings.billing.marketing_hub.free_period_banner.send_broadcast",
              )}
            </Typography.LabelSm>
          </Button>
        </Link>
      </div>
    </div>
  );
};
