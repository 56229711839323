import { Link } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { settingsNavPath } from "@circle-react/helpers/urlHelpers/settingsNavPath";
import { Button } from "@circle-react-shared/uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";

export const FreeTierBanner = () => (
  <div className="mt-4">
    <div className="dark:bg-tertiary rounded-lg bg-blue-100 p-6">
      <Typography.LabelMd color="text-secondary" weight="medium">
        {t("settings.billing.marketing_hub.free_tier_banner.title")}
      </Typography.LabelMd>
      <div className="my-2">
        <Typography.BodySm color="text-secondary">
          {t("settings.billing.marketing_hub.free_tier_banner.banner_text")}
        </Typography.BodySm>
      </div>

      <div className="mt-4">
        <Link to={settingsNavPath.email.broadcast}>
          <Button onClick={function noRefCheck() {}} variant="secondary">
            <Typography.LabelSm>
              {t(
                "settings.billing.marketing_hub.free_period_banner.send_broadcast",
              )}
            </Typography.LabelSm>
          </Button>
        </Link>
      </div>
    </div>
  </div>
);
