import type { MouseEventHandler } from "react";
import { truncate } from "lodash";
import { t } from "@/i18n-js/instance";
import { usePunditUserContext } from "@circle-react/contexts";
import { useBackendProps } from "@circle-react/providers";
import { Icon } from "@circle-react-shared/Icon";
import { LabelMd } from "@circle-react-shared/uikit/Typography/components/Label/LabelMd";
import { useMasqueradeContext } from "../../contexts/masqueradeContext";
import { useIsLoadingAppData } from "../../hooks/useIsLoadingAppData";

export const MasqueradeBanner = () => {
  const { currentCommunityMember, isViewOnlyMasquerading, masqueradingEntity } =
    usePunditUserContext();
  const { masquerade_referrer_url: masqueradeReferrerUrl } = useBackendProps();
  const isLoadingAppData = useIsLoadingAppData();
  const isMasquerading = !!masqueradeReferrerUrl && !isLoadingAppData;

  const {
    isBannerVisible,
    openViewMasqueradingSearch,
    openEditMasqueradingSearch,
    hideBanner,
  } = useMasqueradeContext();

  const handleClose = () => {
    hideBanner();
    if (masqueradeReferrerUrl) {
      window.location.href = masqueradeReferrerUrl;
    }
  };

  if (!isMasquerading && !isBannerVisible) {
    return null;
  }

  let title: string;
  let ariaLabel: string;
  let name: string;
  let onClick: MouseEventHandler<HTMLButtonElement> | undefined;

  // If banner is visible, the possible option is we are in view only mode before
  // selecting an option
  if (isBannerVisible) {
    title = t("view_only_masquerading.banner.title_not_selected");
    ariaLabel = t("view_only_masquerading.banner.title_selected");
    name = t("view_only_masquerading.banner.placeholder");
    onClick = openViewMasqueradingSearch;
  } else if (isViewOnlyMasquerading) {
    // we are masquerading in view only mode
    title = t("view_only_masquerading.banner.title_selected");
    ariaLabel = t("view_only_masquerading.banner.title_selected");
    name = truncate(masqueradingEntity.name, { length: 50 });
    onClick = openViewMasqueradingSearch;
  } else {
    // we are masquerading in edit mode
    title = t("masquerade.banner.title");
    ariaLabel = t("masquerade.banner.title");
    name = truncate(currentCommunityMember?.name || "", { length: 50 });
    onClick = openEditMasqueradingSearch;
  }

  return (
    <div className="flex h-12 items-center justify-between bg-gray-900 px-6 dark:bg-gray-700">
      <div /> {/* Spacer for the exit button */}
      <div className="flex" data-testid="masquerade-banner">
        <div className="mr-2">
          <LabelMd weight="semibold" color="text-feature-dark">
            {title}
          </LabelMd>
        </div>
        <button
          type="button"
          onClick={onClick}
          className="flex"
          aria-label={ariaLabel}
        >
          <div className="border-dark dark:border-secondary border-b border-dashed">
            <LabelMd color="text-feature-dark">{name}</LabelMd>
          </div>
          <div>
            <Icon
              type="20-chevron-down-sm"
              className="text-feature-dark ml-[3px]"
              aria-hidden
            />
          </div>
        </button>
      </div>
      <div>
        <button type="button" onClick={handleClose}>
          <LabelMd color="text-feature-dark" weight="semibold">
            {t("view_only_masquerading.banner.exit")}
          </LabelMd>
        </button>
      </div>
    </div>
  );
};
