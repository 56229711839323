import type { ReactNode } from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

export interface OverlayScrollbarsProps {
  children: ReactNode;
}

export const OverlayScrollbars = ({ children }: OverlayScrollbarsProps) => (
  <OverlayScrollbarsComponent
    className="h-screen"
    options={{
      scrollbars: {
        autoHide: "leave",
        autoHideDelay: 0,
      },
    }}
  >
    {children}
  </OverlayScrollbarsComponent>
);
